<script setup lang="ts">
const signWithX = async () => {
  try {
    const twitterResponse = await apiService.getTwitterLoginUrl(
      useAmplitudeStore().deviceId,
    );
    const { redirectUrl } = twitterResponse.data;
    window.location.href = redirectUrl;
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <button class="text-sm" @click="signWithX">
    <slot>
      Sign up via
      <IconsTweetIcon />
    </slot>
  </button>
</template>
